<template>
  <div class="home">
    <el-container>
      <el-header class="top-box">
        <el-row>
          <el-col :span="12" class="flex items-center">
            <div style="width: 197px;text-align: center;"><img class="logo" src="../assets/logo.png" alt="" /></div>
            <div style="color: #4bb8b3;font-size: 20px;">生美服务平台</div>
          </el-col>
          <el-col :span="12" class="flex justify-end">
            <div class="info-box">
              <div class="info-image">
                <img class="touxiang" src="../assets/11.png" alt="" />
              </div>
              <div class="info-message">
                <strong>{{ data.userName }}</strong>
                <el-button class="out-ptn" @click="loginout" type="text">退出</el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <!-- aside -->
        <el-aside width="215px" style="text-align: center">
          <el-menu :default-active="data.activeIndex" router="true">
            <template v-for="(i, index) in tabList">
              <el-menu-item v-if="i.meta.isShow" :key="i.name" :router="i.path" :index="i.name">
                <i :class="i.meta.icon"></i>
                <template #title>{{ i.meta.title }}</template>
              </el-menu-item>
            </template>
          </el-menu>
        </el-aside>
        <!-- main -->
        <el-main class="elMain">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import router from "../router/index";
import { reactive } from "vue";
import Cookies from "js-cookie";
export default {
  name: "Home",
  setup() {
    let tabList = router.options.routes[0].children;
    let loginout = () => {
      Cookies.set("name", "");
      Cookies.set("userName", "");
      router.push("/");
    };
    let data = reactive({
      userName: Cookies.get("userName"),
      activeIndex: "",
    });
    let updateRoute = () => {
      let index = tabList.findIndex((item) => {
        return item.path == router.currentRoute._value.fullPath;
      });
      data.activeIndex = index;
    };
    updateRoute();
    let handleChangeMenu = (path) => {
      router.replace({
        path: path,
      });
    };
    return {
      tabList,
      loginout,
      data,
      handleChangeMenu,
      updateRoute,
    };
  },
  watch: {
    $route(newRoute) {
      //  this.updateRoute();
    }
  },
};
</script>

<style lang="scss" scoped>
.elMain {
  max-height: calc(100vh - 70px);
}
.el-aside {
  overflow-y: auto;
  max-height: calc(100vh - 70px);
}
.top-box {
  background-color: #efefef;
  padding-top: 6px;
}
.logo {
  height: 44px;
  border-radius: 50%;
}
.info-box {
  display: flex;
  align-items: center;
  .touxiang {
    margin-right: 20px;
    height: 30px;
  }
}
.out-ptn {
  margin-left: 20px;
  font-weight: 500;
  font-size: 16px;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #fff;
  padding-right: 20px;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999;
}
</style>
